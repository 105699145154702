$color-primary:#f73859;
$bg-color: #fff;
$bg-color-1:#fbfbfb;
$bg-color-2:#fff;
$bg-color-3:#f1f1f1;
$bg-color-4:#e0e0e0;
$bg-color-5:rgba(255, 255, 255, 0.7);
$bg-color-6:#fff;
$bg-color-7:rgba(255, 255, 255, 0.8);
$bg-color-8:#e0e0e0;
$text-color:#fff;
$text-color-1:#444;
$text-color-2:#999;
$text-color-3:#333435;
$text-color-4:#444;
$text-color-5:#fff;
$text-color-6:#666;
$text-color-7:#fff;
$border-bottom:$color-primary;
$border-bottom-1:#e5e5e5;
$border-1:#e5e5e5;
$box-shadow-1:rgb(236, 240, 243);
$box-shadow-2:rgb(255, 255, 255);
$box-shadow-3:rgb(236, 240, 243);


@mixin item-bg() {
   border-radius: 8px;
   background: $bg-color-2;
   box-shadow:5px 10px 10px 0px $box-shadow-1;
}

@mixin item-hover() {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px rgba(255, 255, 255, 0.8),
    inset 18px 18px 30px rgba(0, 0, 0, 0.06),
    inset -18px -18px 30px $box-shadow-2;;
    transition: box-shadow .2s ease-out,
    opacity .2s ease-out,
    transform .3s ease-out;
}

