@import '../../styles/theme.scss';

.item {
  overflow: hidden;
  padding-bottom: 5px;
  margin: 0 auto;
  max-width: 1800px;

  :global {
    h3 {
      padding: 0 20px;
      font-weight: normal;
      color: #444;
      line-height: 46px;
    }
  }

  .block {
    float: left;
    box-sizing: border-box;
    width: 20%;
    padding: 0 10px;

    :global {
      .one {
        @include item-bg();
        display: flex;
        outline: none;
        cursor: pointer;
        flex-wrap: wrap;
        margin: 10px 0;
        padding: 10px 15px 15px 15px;
        position: relative;

        a {
          color: #666;
        }
      }

      img {
        border-radius: 45px;
        overflow: hidden;
        // transform: scale(0.85);
        transition: transform 0.5s ease-out;
      }

      .title {
        padding-left: 10px;
      }

      .text {
        display: flex;
        width: 100%;
        overflow: hidden;
        align-items: center;

        .logo {
          flex: 0 0 auto;
          width: 40px;
          height: 40px;
        }

        p {
          color: $text-color-2;
          overflow: hidden;
          line-height: 20px;
          height: 20px;
          font-size: 12px;
          letter-spacing: 1px;
           margin-bottom: 0;
        }

        h4 {
          color: $text-color-1;
          font-weight: normal;
          overflow: hidden;
          font-size: 16px;
          padding: 3px 0 2px 0;
          height: 26px;
          line-height: 20px;
          margin-bottom: 0;
          .ant-tag {
            transform: scale(.8);
            font-size: 12px;
            color: #9c9c9c;
            margin-right: 0;
            background-color: #f1f1f1;
            border: 0px solid #d9d9d9;

            &:nth-child(1) {
              margin-left: 5px;
            }
          }
        }
      }

      .icon {
        width: 28px;
        height: 28px;
        line-height: 28px;
        margin-left: auto;
        text-align: right;

        .i-icon {
          svg {
            transform: translateY(0);
          }
        }
      }

      .access {
        position: absolute;
        right: 15px;
        top: 50%;
        width: 30px;
        height: 30px;
        // text-align: center;
        line-height: 30px;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.2s ease-out, transform 0.3s ease-out;
        transform: translate(-30px, -50%);
        overflow: hidden;
        margin-top: 15px;
      }

      .btn {
        display: flex;
        line-height: 20px;
        padding: 5px;
        flex: 0 1 100%;

        div {
          color: text-color-2;
          flex: 1 0 50%;
          font-size: 12px;

          i {
            padding-right: 2px;
            font-size: 14px;
          }

          .i-icon {
            padding-right: 4px;
          }
        }

        div:nth-child(2) {
          text-align: right;
        }
      }
    }
  }

  @media (any-hover: hover) {
    .block {
      :global {
        .one {
          &:hover {
            @include item-hover();
            .access {
              z-index: 1;
              opacity: 1;
              transform: translate(0, -50%);
            }

            img {
              transform: scale(0.85);
              transition: max-width 0.25s ease-out, transform 0.25s ease-out;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1300px) and (max-width: 1500px) {
    .block {
      width: 25%;
    }
  }

  /*在768 和991 像素之间的屏幕里，小屏幕，主要是PAD*/
  @media (min-width: 768px) and (max-width: 1299px) {
    .block {
      width: 33.333%;
    }
  }

  /*在480 和767 像素之间的屏幕里，超小屏幕，主要是手机*/
  @media (min-width: 480px) and (max-width: 767px) {
    .block {
      width: 50%;
    }
  }

  /*在小于480 像素的屏幕，微小屏幕，更低分辨率的手机*/
  @media (max-width: 479px) {
    .block {
      width: 100%;
    }
  }
}