@import '../../styles/theme.scss';
.header {
    height: 60px;
    padding: 0 30px;
    line-height: 60px;
    backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(255, 255, 255, .7);
    background: rgba(255, 255, 255, .7);
    .nav {
        display: flex;
        justify-content: flex-end;
        :global {
            .active {
                border-bottom: 2px solid $color-primary;
            }
            .ant-col {
                line-height: 60px;
                text-align: center;
                cursor: pointer;
                a{
                    color: #333;
                    display: block;
                }
            }
             .ant-menu-horizontal {
                 border-bottom: 0;
             }
        }

       
    }
    :global {
        .logo {
            img {
                height: 60px;
                padding: 10px 0;
            }
        }


        .ant-menu {
            background: transparent;
        }

        // .ant-btn {
        //     background-color: rgba(255, 255, 255, .7);
        // }
    }

    @media (min-width: 1300px) and (max-width: 1500px) {}

    /*在768 和991 像素之间的屏幕里，小屏幕，主要是PAD*/
    @media (min-width: 768px) and (max-width: 1299px) {
        padding: 0 10px;
    }

    /*在480 和767 像素之间的屏幕里，超小屏幕，主要是手机*/
    @media (min-width: 480px) and (max-width: 767px) {
        padding: 0 10px;
        height: 46px;
        line-height: 46px;
    }

    /*在小于480 像素的屏幕，微小屏幕，更低分辨率的手机*/
    @media (max-width: 479px) {
        padding: 0 10px;
        height: 46px;
        line-height: 46px;
        .nav {
            :global {
                .ant-col {
                    line-height: 46px;
                }
            }
        }
        :global {
            .logo {
                img {
                    height: 35px;
                    padding: 4px 0;
                }
            }
        }
    }
}

.sign {
    padding: 15px 25px;

    :global {
        .ant-modal-body {
            padding-top: 50px;
        }

        #login_container {
            text-align: center;
            height: 280px;
        }

        .ant-tabs-nav-scroll {
            text-align: center;
        }

        .wx {
            text-align: center;

            p {
                line-height: 26px;
                padding-top: 2px;
                text-align: center;
            }
        }

        h4 {
            text-align: center;
            font-weight: normal;
            padding-bottom: 15px;
        }

        .party {
            text-align: center;
            margin-top: 15px;

            p {
                text-align: center;
                padding-top: 6px;

                span {
                    letter-spacing: 3px;
                }
            }

            a {
                display: block;
                padding-top: 10px;
                text-align: center;
                color: #666;
            }

            img {
                height: 42px;
            }
        }
    }
}