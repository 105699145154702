@import '../../styles/theme.scss';
.isUpdate {
    :global {
        .ant-modal-content{
            background: url('/gx-bg.png') no-repeat !important;
            background-size: 520px auto;
            background-color: #fff !important;
        }
        .ant-modal-confirm-content {
            min-height: 300px;
            h3 {
                color: #fff;
                font-size: 30px;
                font-weight: normal;
                line-height: 42px;
                padding-left: 30px;
                height: 210px;

                p {
                    font-size: 18px;
                    line-height: 32px;
                    color: #fff;
                }
            }

            p {
                line-height: 28px;
                color: #444;
            }
        }
    }
}

.index {
    :global {
        .nav {
            width: 100%;
            height: 47px;


            .ant-tabs-nav-scroll {
                text-align: center;
            }

            .ant-affix {
                border-bottom: 1px solid #e5e5e5;
                background: hsla(0, 0%, 100%, .7);
            }

            .nav-list {
                margin: 9px 30px;
                max-width: 1780px;
                display: flex;
                justify-content: space-between;
            }

            .ant-tag {
                padding: 4px 20px;
                font-size: 14px;
                cursor: pointer;
                // color: #444;
                margin-right: 0;
                background-color: rgba(255, 255, 255, .8);
                border: 0px solid #fbfbfb;
            }

            .ant-anchor-wrapper {
                background-color: transparent;

                .ant-anchor-ink {
                    display: none;
                }

                .ant-anchor-link {
                    padding: 0;
                }

                .ant-anchor-link-active {
                    .ant-tag {
                        background-color: $color-primary !important;
                    }
                }
            }
        }
    }

    .content {
        margin: 20px;
        :global {
            .more {
                line-height: 46px;
                text-align: right;
                padding-right: 20px;
                cursor: pointer;
            }
        }
    }

    @media screen and (min-width:1860px) {
        :global {
            .nav {
                .nav-list {
                    margin: 9px auto;
                }
            }
        }
    }

    @media (min-width: 1300px) and (max-width: 1500px) {}

    /*在768 和991 像素之间的屏幕里，小屏幕，主要是PAD*/
    @media (min-width: 768px) and (max-width: 1299px) {
        :global {
            .nav {
                height: 45px;
                background: rgba(255, 255, 255, 0.7);
                .ant-affix {
                    border-bottom: 0;
                }
            }
        }
    }

    /*在480 和767 像素之间的屏幕里，超小屏幕，主要是手机*/
    @media (min-width: 480px) and (max-width: 767px) {
        .content {
            margin: 5px;
        }

        :global {
            .nav {
                height: 38px;
                background: rgba(255, 255, 255, 0.7);
                .ant-affix {
                    border-bottom: 0;
                }
            }
        }
    }

    /*在小于480 像素的屏幕，微小屏幕，更低分辨率的手机*/
    @media (max-width: 479px) {
        .content {
            margin: 5px;
        }

        :global {
            .nav {
                height: 38px;
                background: rgba(255, 255, 255, 0.7);
                .ant-affix {
                    border-bottom: 0;
                }
            }
        }
    }
}