.footer {
  background: #fff;
  margin-top: 60px;

  :global {
    .content {
      margin: 0 30px;
    }

    .text {
      line-height: 26px;

      .ant-col {
        &:nth-child(1) {
          display: flex;
          align-items: center;
        }

        &:nth-child(2) {
          display: flex;
          justify-content: flex-end;
        }
      }

      img {
        height: 130px;
      }

      a {
        color: #444;
        padding: 10px 0 10px 40px;

        p {
          margin-bottom: 0;
          text-align: center;
          padding: 8px 0;
        }
      }
    }

    .copyright {
      text-align: center;
      line-height: 40px;
      border-top: 1px solid rgba(188, 198, 208, 0.4);
      font-size: 12px;
    }

    .link {
      padding-top: 10px;

      a {
        color: #999;
        padding: 10px;
      }

      p {
        line-height: 30px;
      }

    }

    .cooperation {
      padding-bottom: 20px;

      h4 {
        padding-bottom: 10px;
        font-weight: normal;
      }

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          flex: 1 1 auto;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px 0;

            img {
              height: 25px;
              width: auto;
              filter: grayscale(100%);
              filter: gray;
            }

            &:hover {
              img {
                filter: grayscale(0%);
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1300px) and (max-width: 1500px) {}

  /*在768 和991 像素之间的屏幕里，小屏幕，主要是PAD*/
  @media (min-width: 768px) and (max-width: 1299px) {
    :global {
      .content {
        margin: 0 30px;
        padding-top: 20px;
      }
    }
  }

  /*在480 和767 像素之间的屏幕里，超小屏幕，主要是手机*/
  @media (min-width: 480px) and (max-width: 767px) {
    :global {
      .content {
        margin: 0 30px;
        padding-top: 20px;
      }
    }
  }

  /*在小于480 像素的屏幕，微小屏幕，更低分辨率的手机*/
  @media (max-width: 479px) {
    :global {
      .content {
        margin: 0 30px;
        padding-top: 20px;
      }
    }
  }

}